<template>
  <div id="CurrencyList">
    <body-container>
      <vj-table @loadTable="onSearch" :page-obj="pageObj" :loading="loading" :cfg-list="cfgList" :table-data="tableData">
      </vj-table>
    </body-container>
  </div>
</template>
<script lang="ts">
import Vue, { ComponentOptions, VueConstructor } from "vue";
import Component from "vue-class-component";
import BodyContainer from "@/components/layout/body-container.vue";
import SearchFormContainer from "@/components/layout/search-form-conatiner.vue";
import { Mixins, Ref } from "vue-property-decorator";
import { TableMixin } from "@/plugins/mixins/table";
import { requestByDel, requestByPost } from "@/utils/request";
import TextButton from "@/components/basic/text-button.vue";
import CurrencyPopup from "@/components/popup/currency-popup.vue";
import { warnConfirm } from "@/utils/logic";
import { hookLoading, hookSubmit } from "@/components/vj-lib/utils";
import { CurrencySH } from "@/config/search-forms";
import { CurrencyTB, SysLogsTB } from "@/config/tables";

export const title = "系统日志";
@Component({
  components: { TextButton, SearchFormContainer, BodyContainer },
})
export default class CurrencyList extends Mixins(TableMixin) implements ComponentOptions<Vue> {
  name = "SysLogsList";
  cfgList = SysLogsTB;

  @hookLoading()
  async onSearch(num?, size?) {
    const res = await requestByPost(`/sysLog//pageList/${num}/${size}`, {});
    this.tableData = res.data;
    return res;
  }
  created() {
    this.onSearch();
  }
}
</script>

<style scoped lang="scss">
@import "~assets/common.scss";

#CurrencyList {
}
</style>
