<template>
  <div id="loginContainer" @click="showVerify = false">
    <div class="wrapper fadeInDown">
      <form @submit="void 0" id="formContent" v-loading="loading">
        <h2 class="active">请登录您的帐号</h2>
        <div class="fadeIn first">
          <img src="../../assets/img/login_head_icon.svg" id="icon" alt="User Icon" />
        </div>
        <div>
          <el-form style="padding: 0 70px; margin-top: 50px">
            <el-form-item>
              <el-input clearable v-model="form.username" placeholder="请输入账号" />
            </el-form-item>
            <el-form-item>
              <el-input show-password clearable v-model="form.password" placeholder="请输入密码" />
            </el-form-item>
            <el-form-item>
              <el-input show-password clearable v-model="form.idNo" placeholder="请输入谷歌验证码" />
            </el-form-item>
          </el-form>
          <div class="submit-wrap">
            <verify ref="verify" class="c-verify" v-show="showVerify" verify-type="LOGIN" @change="login" />
            <input type="submit" @click.prevent.stop.self="showVerify = true" class="fourth" value="登录" />
          </div>
        </div>
      </form>
    </div>
    <el-dialog :show-close="false" title="密码修改" :visible.sync="showPasswordDialog">
      <el-form ref="ruleFormRef" :rules="rules" v-loading="loading" :model="passwordForm">
        <el-form-item prop="password" label="新密码">
          <el-input clearable v-model="passwordForm.password" placeholder="请输入" />
        </el-form-item>
        <el-form-item prop="confirmPassword" label="二次确认密码">
          <el-input clearable v-model="passwordForm.confirmPassword" placeholder="请输入" />
        </el-form-item>
      </el-form>
      <template #footer>
        <el-button :loading="loading" type="primary" @click="changePasswordHandle">确认修改</el-button>
        <el-button :loading="loading" @click="showPasswordDialog = false">取消</el-button>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { requestByPost } from "@/utils/request";
import Verify from "@/components/verify/index";
import { BACKEND_PATH_PURE, customStore } from "@/config/properties";

export default {
  name: "loginContainer",
  components: { Verify },
  data() {
    return {
      rules: {
        password: [
          { required: true, message: "请输入密码", trigger: "blur" },
          { min: 12, max: 20, message: "密码长度需在12到20位之间", trigger: "blur" },
          { validator: this.validatePassword, trigger: "blur" },
        ],
        confirmPassword: { required: true, validator: this.validatePass2, trigger: "blur" },
      },
      loading: false,
      showPasswordDialog: false,
      temporaryAuthToken: "",
      showVerify: false,
      userNameFocus: false,
      passWordFocus: false,
      passwordForm: {
        password: "",
        confirmPassword: "",
      },
      form: {
        idNo: "",
        username: "",
        password: "",
      },
    };
  },
  computed: {},
  methods: {
    validatePassword(rule, value, callback) {
      // 密码校验规则：包含大小写字母、数字和特殊符号
      const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{12,20}$/;
      if (regex.test(value)) return callback();
      return callback(new Error("密码需包含大小写字母、数字和特殊符号"));
    },
    validatePass2(rule, value, callback) {
      if (value !== this.passwordForm.password) {
        return callback(new Error("两次密码输入不一致!"));
      }
      return callback();
    },
    async login({ status, uuid }) {
      if (!status) return this.$message.error("校验失败");
      this.showVerify = false;
      this.loading = true;
      const { username, password, idNo } = this.form;
      const {
        data: { token, sysUser = {}, isNeedUpdatePassword },
      } = await requestByPost(
        `${BACKEND_PATH_PURE}/passport/ajaxLogin`,
        {
          idNo,
          loginName: username,
          password,
        },
        { headers: { uuid } }
      ).finally(() => {
        this.loading = false;
        this.$refs.verify.init();
      });
      if (isNeedUpdatePassword) {
        await this.$confirm("您已经90天内未修改密码，为保证您的账号安全，现在需要修改密码后才能进行登录！", "提示", {
          showCancelButton: false,
          showClose: false,
        });
        this.showPasswordDialog = true;
        this.temporaryAuthToken = token;
        return;
      }
      customStore.userInfo = sysUser;
      localStorage.setItem("userInfo", JSON.stringify(sysUser));
      localStorage.setItem("Authorization", token);
      this.$message.success("登录成功！");
      await this.initPreData();
      return this.$router.replace("/");
      // this.$router.go(-1);
    },
    async changePasswordHandle() {
      await this.$refs.ruleFormRef.validate();
      this.loading = true;
      await requestByPost(`sysUser/updatePassword`, this.passwordForm, {
        headers: { Authorization: this.temporaryAuthToken },
      }).finally(() => (this.loading = false));
      this.showPasswordDialog = false;
      this.$message.success("密码修改完成，请重新登录！");
    },
  },
  created() {
    // this.login();
  },
};
</script>

<style lang="scss" scoped>
@import "~assets/scss/login";
@import "~assets/common.scss";

.c-verify {
  position: absolute;
  left: 50%;
  transform: translate(-50%, -104%);
  z-index: 10;
}

.submit-wrap {
  position: relative;
}

.captcha {
  display: inline-block;
  cursor: pointer;
  width: 100px;
  border: 1px solid #ccc;
  margin-left: 10px;
  border-radius: 3px;
  overflow: hidden;
}
</style>
