import { Message, MessageBox } from "element-ui";
import { defaultHeaders, requestByPost } from "@/utils/request";
import axios, { AxiosResponse } from "axios";

/***
 * 如果token失效跳到首页
 * 会经过router拦截
 * 此时已把登录token删除 所以此时会被拦截去登录页
 */
/***
 * 初始化http请求拦截
 */
export function initHttpInterceptor(instance) {
  instance.interceptors.request.use((v) => {
    return v;
  });
  instance.interceptors.response.use(
    async function (response: AxiosResponse) {
      const { result, error, message } = response.data;
      if (result) return response;
      if (response.status === 302) {
        localStorage.clear();
        return location.reload();
      }
      if (error?.code === 401) {
        const { token } = await requestByPost(`/auth/refreshToken`);
        localStorage.setItem("Authorization", token);
        Object.assign(response.config.headers, defaultHeaders());
        return axios.request(response.config);
      }
      if (error?.code === 6003) {
        localStorage.clear();
        return location.reload();
      }
      Message.error(error?.message || message || "请求错误");
      throw new Error("请求错误");
    },
    (error) => {
      console.log(error.message);
      if (error.message.includes("Network Error")) {
        localStorage.clear();
        return location.reload();
      }
      Message.error(error);
    }
  );
}
